<template>
  <div class="flex justify-center items-center my-5">
    <transition appear v-if="!interviewsAreLoading">
      <div class="w-3/4 bg-teal rounded-xl mb-2 transition appear">
        <h3 class="text-lg text-white text-center pt-2">
          <span v-if="getUpcomingInterviews.length < 1">No </span>Upcoming
          Interviews
        </h3>
        <vueper-slides
          v-if="!interviewsAreLoading"
          fixed-height="270px"
          class="no-shadow"
          :visible-slides="3"
          :dragging-distance="20"
          :gap="-2"
          :bullets-outside="false"
          :arrows-outside="false"
        >
          <vueper-slide
            v-for="interview in getUpcomingInterviews"
            :key="interview?.id"
          >
            <template v-slot:content>
              <slide
                :icon="'meeting_room'"
                :title="interview?.user?.email"
                :route="{
                  name: 're-start-interview',
                  params: { interviewId: interview.id },
                }"
              />
            </template>
          </vueper-slide>
        </vueper-slides>
      </div>
    </transition>
    <template v-else>
      <div class="w-3/4 bg-teal rounded-xl mb-2">
        <table-skeleton />
      </div>
    </template>
  </div>
  <div class="flex justify-center">
    <router-link :to="{ name: 're-create-new-interview' }">
      <horizontal-item-card :icon="'interpreter_mode'" :title="'New Interview'">
      </horizontal-item-card>
    </router-link>
    <router-link :to="{ name: 're-new-offers' }">
      <horizontal-item-card :icon="'inventory'" :title="'New Offer'">
      </horizontal-item-card>
    </router-link>
    <router-link :to="{ name: 're-offers-inbox' }">
      <horizontal-item-card :icon="'email'" :title="'Offer Inbox'">
      </horizontal-item-card>
    </router-link>
  </div>

  <div class="mb-4 border-b w-11/12 mx-auto mt-10">
    <ul class="flex flex-wrap -mb-px">
      <li class="mr-2" v-for="item in tabs" :key="item.value">
        <button
          class="inline-block py-4 px-4 text-sm font-medium text-center rounded-t-lg border-b-2 border-transparent hover:text-teal hover:border-teal"
          :class="
            activeTab === item.value ? 'text-teal border-teal' : 'text-grey'
          "
          @click="changeTab(item.value)"
        >
          {{ item?.title }}
        </button>
      </li>
    </ul>
  </div>
  <!-- table -->
  <div class="w-11/12 mx-auto text-center text-grey my-2">
    <template v-if="!offersAreLoading">
      <el-table
        header-row-class-name="bg-gray-200"
        header-cell-class-name="text-teal py-5 align-top"
        :cell-style="{ padding: 'unset !important' }"
        row-class-name="border-t"
        class="w-full rounded-xl overflow-hidden bg-gray-100"
        :data="tableData[activeTab]"
        style="width: 100%"
      >
        <el-table-column
          v-for="item in tableHeaders[activeTab]"
          :key="item.index"
          :prop="item.prop"
          :label="item.title"
        >
          <template #default="scope">
            <div v-if="item.id === 'app_status'">
              {{ scope.row.is_completed ? "Completed" : "Scheduled" }}
            </div>
            <div class="" v-if="item.id === 'fullname'">
              {{
                scope?.row?.user?.profile?.first_name +
                " " +
                scope?.row?.user?.profile?.last_name
              }}
            </div>
            <div v-if="item.id === 'requestStatus'">
              <span v-if="scope.row.applicant_status == 0">Declined</span>
              <span v-if="scope.row.applicant_status == 1">Approved</span>
              <span v-if="scope.row.applicant_status == 2"
                >Referred for 2nd Interview</span
              >
            </div>
            <div class="" v-if="item.id === 'date'">
              {{ new Date(scope?.row?.created_at).toLocaleDateString("en-GB") }}
            </div>
            <div class="" v-if="item.id === 'actions'">
              <table-options-dropdown
                :show="showDropDown"
                :id="scope?.row?.id"
                :options="
                  activeTab === 'first_interviews' &&
                  scope?.row?.applicant_status === 2
                    ? dropdownFirstInterview
                    : dropdownOptions
                "
                :dynamicParam="
                  activeTab === 'first_interviews' &&
                  scope?.row?.applicant_status === 2
                    ? scope.row.user_id
                    : scope.row.id
                "
                @close="showDropDown = !showDropDown"
                @click="showDropDown = !showDropDown"
              >
                <span class="material-icons"> more_horiz </span>
              </table-options-dropdown>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <template v-else>
      <table-skeleton />
    </template>
    <div class="flex justify-center my-5">
      <el-pagination
        background
        layout="pager"
        :total="totalEntries"
        @current-change="change"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { markRaw } from "vue";
import customPaginate from "@/components/ui/baseComponents/paginate.vue";
import tableSkeleton from "@/components/ui/baseComponents/skeletons/tableSkeleton.vue";
import slide from "@/components/ui/baseComponents/slide.vue";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import horizontalItemCard from "@/components/ui/baseComponents/horizontalItemCard.vue";
import tableOptionsDropdown from "@/components/ui/baseComponents/tableOptionsDropdown.vue";
export default {
  components: {
    VueperSlides,
    VueperSlide,
    slide,
    horizontalItemCard,
    tableSkeleton,
    tableOptionsDropdown,
  },
  created() {
    this.loadOffers(this.page);
    this.past_interviews();
    this.upcomingInterviews();
    this.firstInterviews();
    this.secondInterviews();
    if (this.$route.query.success === "true") {
      this.$toast.success(`Success`);
    } else if (this.$route.query.success === "false") {
      this.$toast.error(`Error`);
    }
  },

  data() {
    return {
      offersAreLoading: false,
      interviewsAreLoading: false,
      page: 1,
      options: {
        template: markRaw(customPaginate),
      },
      tableHeaders: {
        all_interviews: [
          { title: "Candidate Name", prop: "user.profile", id: "fullname" },
          { title: "Application Date", prop: "created_at", id: "date" },
          { title: "Interview Date", prop: "date", id: "interview_date" },
          {
            title: "Interview",
            prop: "application_status",
            id: "application_status",
          },
          {
            title: "Interview Status",
            prop: "is_completed",
            id: "app_status",
          },
          {
            title: "Role Applied For",
            prop: "hiring_request.job_title",
            id: "role",
          },
          {
            title: "Location",
            prop: "location",
            id: "location",
          },
          {
            title: "Request Status",
            prop: "applicant_status",
            id: "requestStatus",
          },
          {
            title: "Actions",
            prop: "user",
            id: "actions",
          },
        ],
        past_interviews: [
          { title: "Interview Date", prop: "created_at", id: "date" },
          { title: "Time", prop: "time", id: "time" },
          { title: "Location", prop: "location", id: "location" },
          {
            title: "Candidate Name",
            prop: "user.profile.first_name",
            id: "fullname",
          },
          {
            title: "Interview Status",
            prop: "is_completed",
            id: "app_status",
          },
          {
            title: "Interview",
            prop: "application_status",
            id: "application_status",
          },
          {
            title: "Role Applied For",
            prop: "hiring_request.job_title",
            id: "role",
          },
          {
            title: "Request Status",
            prop: "applicant_status",
            id: "requestStatus",
          },
          {
            title: "Actions",
            prop: "user",
            id: "actions",
          },
        ],
        first_interviews: [
          { title: "Interview Date", prop: "created_at", id: "date" },
          { title: "Time", prop: "time", id: "time" },
          { title: "Location", prop: "location", id: "location" },
          {
            title: "Candidate Name",
            prop: "user.profile.first_name",
            id: "fullname",
          },
          {
            title: "Interview Status",
            prop: "is_completed",
            id: "app_status",
          },
          {
            title: "Role Applied For",
            prop: "hiring_request.job_title",
            id: "role",
          },
          {
            title: "Request Status",
            prop: "applicant_status",
            id: "requestStatus",
          },
          {
            title: "Actions",
            prop: "user",
            id: "actions",
          },
        ],
        second_interviews: [
          { title: "Interview Date", prop: "created_at", id: "date" },
          { title: "Time", prop: "time", id: "time" },
          { title: "Location", prop: "location", id: "location" },
          {
            title: "Candidate Name",
            prop: "user.profile.first_name",
            id: "fullname",
          },
          {
            title: "Interview Status",
            prop: "is_completed",
            id: "app_status",
          },
          {
            title: "Role Applied For",
            prop: "hiring_request.job_title",
            id: "role",
          },
          {
            title: "Request Status",
            prop: "applicant_status",
            id: "requestStatus",
          },
          {
            title: "Actions",
            prop: "user",
            id: "actions",
          },
        ],
      },

      activeTab: "all_interviews",
      tabs: [
        { title: "All Interviews", value: "all_interviews" },
        {
          title: "Past Interviews",
          value: "past_interviews",
        },
        {
          title: "First Interviews",
          value: "first_interviews",
        },
        {
          title: "Second Interviews",
          value: "second_interviews",
        },
      ],

      showDropDown: false,
      showDeleteDialog: false,
      pageNumber: 1,
      totalEntries: 10,
    };
  },
  methods: {
    deleteAction() {
      this.showDeleteDialog = !this.showDeleteDialog;
    },
    async loadOffers() {
      this.offersAreLoading = true;
      try {
        const offers = await this.$store.dispatch(
          "REgeneralModule/fetchAllInterviews",
          {
            page: this.pageNumber,
          }
        );

        if (offers) {
          this.offersAreLoading = false;
        }
        this.totalEntries = offers.data["interviews"].total;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    async past_interviews() {
      try {
        const past_interviews = await this.$store.dispatch(
          "REnewHire/fetchPastInterviews",
          {
            page: this.pageNumber,
          }
        );
        if (past_interviews) {
          this.offersAreLoading = false;
        }
        this.totalEntries = past_interviews.data["interviews"].total;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
      this.offersAreLoading = false;
    },
    async upcomingInterviews() {
      this.interviewsAreLoading = true;
      try {
        await this.$store.dispatch("REnewHire/fetchUpcomingInterviews");
        this.interviewsAreLoading = false;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    async firstInterviews() {
      try {
        const offers = await this.$store.dispatch(
          "REnewHire/fetchFirstInterviews",
          {
            application_status: "first-interview",
            page: this.pageNumber,
          }
        );
        this.totalEntries = offers.data["interviews"].total;
        this.interviewsAreLoading = false;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    async secondInterviews() {
      try {
        const offers = await this.$store.dispatch(
          "REnewHire/fetchSecondInterviews",
          {
            application_status: "second-interview",
            page: this.pageNumber,
          }
        );
        this.totalEntries = offers.data["interviews"].total;
        this.interviewsAreLoading = false;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    change(value) {
      this.pageNumber = value;
      switch (this.activeTab) {
        case "past_interviews":
          this.loadOffers();
          break;

        case "first_interviews":
          this.firstInterviews();
          break;
        case "second_interviews":
          this.secondInterviews();
          break;

        default:
          this.loadOffers();
      }
    },
    changeTab(value) {
      this.activeTab = value;
      this.pageNumber = 1;
    },
  },

  computed: {
    dropdownOptions() {
      return [
        {
          type: "link",
          linkName: "re-view-interview",
          paramName: "interviewId",
          label: "View",
        },
      ];
    },
    dropdownFirstInterview() {
      return [
        {
          type: "link",
          linkName: "re-create-new-interview-with-user",
          paramName: "id",
          label: "Schedule 2nd Interview",
        },
      ];
    },
    getOffers() {
      return this.$store.getters["REgeneralModule/getAllInterviews"];
    },
    getUpcomingInterviews() {
      return this.$store.getters["REnewHire/getUpcomingInterviews"];
    },
    getPastInterviews() {
      return this.$store.getters["REnewHire/getPastInterviews"];
    },
    getFirstInterviews() {
      return this.$store.getters["REnewHire/getFirstInterviews"];
    },
    getSecondInterviews() {
      return this.$store.getters["REnewHire/getSecondInterviews"];
    },
    tableData() {
      return {
        all_interviews: this.getOffers,
        past_interviews: this.getPastInterviews,
        first_interviews: this.getFirstInterviews,
        second_interviews: this.getSecondInterviews,
      };
    },
  },

  watch: {
    activeTab: {
      handler(newValue, oldValue) {
        if (newValue != oldValue) {
          this.pageNumber = 1;
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.vueperslide {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0.9);
  transition: 0.3s ease-in-out;
  height: 85%;
}
.vueperslide--active {
  transform: scale(1.1);
  transition: 0.3s ease-in-out;
}
.el-table >>> .cell {
  padding: unset !important;
}
</style>
