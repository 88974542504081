<template>
  <div
    class="flex justify-center items-center shadow-lg hover:shadow-2xl rounded-2xl w-64 p-4 bg-white text-teal hover:text-orange transition-all duration-500 cursor-pointer m-4"
  >
    <div class="flex flex-col items-center justify-center">
      <div class="">
        <span class="material-icons text-5xl"> {{ icon }} </span>
      </div>
      <p class="text-center font-medium text-grey text-md">{{ title }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
